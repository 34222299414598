import React from "react";

const Alert = () => (
  <div className="alert alert-dark" role="alert">
    <h5 className="alert-heading">Tried our new apps?</h5>
    <p>
      We noticed you are on an Apple device. Try our new app,{" "}
      <a href="https://apps.apple.com/us/app/caltrain-companion/id6737343619">
        Caltrain Companion, available in the app store
      </a>
      !
    </p>
    <br></br>
    <p>
      Caltrain_WiFi captive portal not launching?{" "}
      <a href="https://www.caltraincompanion.com/wifi">
        Check out Caltrain Wi-Fi Helper
      </a>
      , a MacOS app that will automatically connect you to the Caltrain WiFi
      captive portal.
    </p>
  </div>
);

export default Alert;
